<template>
  <div data-cy="offer-guarantees">
    <ModalWarning
      v-if="hasWarningModalToBeDisplayed"
      :title="warningModalInfo.title"
      :reason="warningModalInfo.reason"
      :advice="warningModalInfo.advice"
      @close="onCloseWarningModal"
    ></ModalWarning>
    <ModalConfirmation
      v-if="isCommissionsAndTaxesModalOpen(programProductId)"
      :title="$t('modal.confirmation.program_product.title')"
      :message="$t('modal.confirmation.program_product.message')"
      modal-size="large"
      @confirm="onValidationProgramProduct"
      @close="onConfirmationModalClose"
    ></ModalConfirmation>
    <div class="offer-guarantees-page" :key="programProductId">
      <ProgramContentHeader
        class="offer-page__header"
        :program="getProgramByOfferId(parseInt(offerId))"
        :title="getProgramByOfferId(parseInt(offerId)).name"
        :desc="desc"
        :is-share-button-visible="true"
      ></ProgramContentHeader>
      <div class="offer-guarantees">
        <p class="offer-guarantees__title font-size-16 text-uppercase">
          {{ title }}
          <strong :data-cy="`product-${programProductId}-name`">
            {{ getProgramProductName(programProductId) }}
          </strong>
          {{ offerTitle }}
        </p>
        <spinner v-if="isLoading || isProgramProductCustomizationLoading"></spinner>
        <transition v-else name="fade" mode="out-in">
          <div v-if="isOfferExternal(offerId)">
            <Flitter
              :program-product-id="programProductId"
              :is-customization-mode="true"
              @validate-program-product="onValidationProgramProduct"
            ></Flitter>
          </div>
          <div v-else>
            <div
              v-for="(productLineId, index) in listProductLinesIds"
              :key="productLineId"
            >
              <FormProductLine
                :can-be-displayed="productLineIdsToDisplay.includes(productLineId)"
                :product-line-id="productLineId"
                :program-product-id="programProductId"
                :offer-id="offerId"
                :disabled="isDisabled"
                :index="index"
                :data-cy="`product-line-item-${index}`"
                @update-vehicle-within-program="
                  updateVehicleWithinProgram(productLineId)
                "
              ></FormProductLine>
            </div>
            <div class="buttons">
              <div class="add-button">
                <SpinnerButton
                  :disabled="isDisabled"
                  class="btn btn-light-gray btn-block"
                  @click="onAddProductLineButtonClick"
                  data-cy="add-product-line-button"
                  :is-loading="isProductLineAdditionInProgress"
                  :text="$t('button.add_product_line')"
                ></SpinnerButton>
              </div>
              <div class="validate-button">
                <button
                  :disabled="isDisabled"
                  class="btn btn-primary btn-block"
                  @click="onValidateButtonClick"
                  data-cy="validate-button"
                >
                  {{ $t("button.validate") }}
                </button>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import FormProductLine from "../FormProductLine"
import { mapGetters } from "vuex"
import FormProductLineMixin from "../mixins/FormProductLineMixin"
import ProgramContentHeader from "./ProgramContentHeader"
import ProgramProductCustomizationService from "../../../services/pages/ProgramProductCustomizationService"
import SpinnerButton from "../../button/SpinnerButton"
import ModalConfirmation from "@/components/modal/ModalConfirmation"
import ProgramService from "../../../services/business/ProgramService"
import Flitter from "@/components/presales/components/Flitter.vue"
import ModalWarning from "../../modal/ModalWarning"

export default {
  name: "ProgramProductCustomization",
  components: {
    Flitter,
    FormProductLine,
    ProgramContentHeader,
    SpinnerButton,
    ModalConfirmation,
    ModalWarning,
  },
  mixins: [FormProductLineMixin],
  props: {
    offerId: { type: [Number, String], required: true },
    programProductId: {
      type: [Number, String],
      required: true,
    },
    isDisabled: { type: Boolean, default: false },
  },
  data() {
    return {
      isLoading: true,
      isProductLineAdditionInProgress: false,
      productLineIdsToDisplay: [],
    }
  },
  computed: {
    ...mapGetters("programProduct", ["getProgramProductName"]),
    ...mapGetters("program", [
      "getProgramByOfferId",
      "getProgram",
      "listProgramProductLinesWithoutVehicle",
    ]),
    ...mapGetters("productLine", [
      "listProductLines",
      "hasVehicleChoiceError",
      "getVehicleChoiceErrorDetail",
    ]),
    ...mapGetters("offer", [
      "isLastProgramProduct",
      "getNextProgramProductId",
      "getOfferNumberInProgram",
      "listEligibilityCriteriaValues",
      "isOfferExternal",
    ]),
    ...mapGetters("auth", ["getRouteName", "getSharingToken", "isLoggedInSharingMode"]),
    ...mapGetters("programProductCustomization", [
      "isProgramProductCustomizationLoading",
      "listProductLinesIds",
      "hasProgramProductSeveralProductLines",
      "hasDifferentCommercialConfigInProductLines",
      "getLastAddedProductLineId",
      "getEligibilityCriterionValue",
      "listCustomEligibilityCriteria",
      "getVehicle",
      "isVehicleSelected",
    ]),
    ...mapGetters("programProductModal", ["isCommissionsAndTaxesModalOpen"]),
    ...mapGetters("offerContext", ["isStoreInit"]),
    title() {
      return `${this.$t("offers.offer_guarantees.title")}`
    },
    desc() {
      return `${this.$t("offers.description")}${
        this.offerNumber
      } - ${this.getProgramProductName(this.programProductId)}`.toUpperCase()
    },
    offerNumber() {
      return this.getOfferNumberInProgram(parseInt(this.offerId))
    },
    offerTitle() {
      return `
      ${this.$t("offers.offer_guarantees.of")} ${this.$t("offers.offer")} #${parseInt(
        this.offerNumber
      )}
      `
    },
    hasWarningModalToBeDisplayed() {
      return this.hasVehicleChoiceError
    },
    warningModalInfo() {
      return {
        title: this.$t("programs.update_vehicle_choice.title"),
        reason: this.getVehicleChoiceErrorDetail,
      }
    },
  },
  methods: {
    onCloseWarningModal() {
      this.$store.dispatch("productLine/resetVehicleChoiceError")
      ProgramProductCustomizationService.setIsVehicleUpdateWithinProgramInProgress(
        false
      )
    },
    onConfirmationModalClose() {
      this.$store.dispatch("programProductModal/deleteStore")
    },
    async onValidateButtonClick() {
      if (
        this.hasProgramProductSeveralProductLines &&
        this.hasDifferentCommercialConfigInProductLines
      ) {
        this.$store.dispatch("programProductModal/handleModalOpening", {
          modalName: "COMMISSIONS_AND_TAXES",
          programProductId: this.programProductId,
        })
      } else {
        this.onValidationProgramProduct()
      }
    },
    async onValidationProgramProduct() {
      await this.$store.dispatch("programProduct/activate", this.programProductId)
      // refresh offer
      await this.$store.dispatch("offer/getOffer", this.offerId)

      if (!this.isLastProgramProduct(this.offerId, this.programProductId)) {
        await this.$router.push({
          name: this.getRouteName("programProduct"),
          params: {
            programProductId: this.getNextProgramProductId(
              this.offerId,
              this.programProductId
            ),
          },
        })
      } else {
        if (this.isOfferExternal(this.offerId)) {
          await this.validateOfferAndGoToNextStep()
        } else {
          await this.$router.push({
            name: this.getRouteName("offerPricingRange"),
            // in sharing space, we need to pass the program id as it's not in the URL
            params: this.getSharingToken
              ? { id: this.getProgramByOfferId(this.offerId).id }
              : {},
          })
        }
      }
    },
    async validateOfferAndGoToNextStep() {
      await this.$store.dispatch("offer/activate", this.offerId)
      const program = this.getProgramByOfferId(parseInt(this.offerId))

      if (this.hasAnotherOfferToConfigure()) {
        const nextOfferId = parseInt(this.offerId) + 1
        await this.$store.dispatch("offer/setCurrentOfferId", nextOfferId)
        await this.$router.push({
          name: this.getRouteName("programOffer"),
          params: { offerId: nextOfferId },
        })
      } else {
        await this.$router.push({
          name: this.getRouteName("programHome"),
          params: { id: program.id },
        })
      }
    },
    hasAnotherOfferToConfigure() {
      this.program = this.getProgramByOfferId(parseInt(this.offerId))
      const numberOfOffers = this.program.offers.length
      return (
        numberOfOffers > 1 &&
        this.program.offers[numberOfOffers - 1].id !== parseInt(this.offerId)
      )
    },
    async onAddProductLineButtonClick() {
      this.isProductLineAdditionInProgress = true
      const programId = this.getProgramByOfferId(parseInt(this.offerId)).id
      await ProgramProductCustomizationService.addProductLine(
        programId,
        this.programProductId
      )
      if (this.isLoggedInSharingMode) {
        await this.$store.dispatch("program/getSharedProgram", this.getSharingToken)
      } else {
        await this.$store.dispatch("program/getProgram", this.$route.params.id)
      }
      this.isProductLineAdditionInProgress = false
      this.productLineIdsToDisplay = this.listProductLinesIds
    },
    listEligibleEngineCodes() {
      const eligibleEngineCodesInEligibilityCriteria =
        this.listEligibilityCriteriaValues(this.$route.params.offerId).engine_code
      return eligibleEngineCodesInEligibilityCriteria.length > 0
        ? eligibleEngineCodesInEligibilityCriteria
        : ProgramService.listEngineCodesFromEngineTag(this.getProgram.engine_tag.label)
    },
    async updateVehicleWithinProgram(productLineId) {
      ProgramProductCustomizationService.setIsVehicleUpdateWithinProgramInProgress(true)
      const vehicle = this.getVehicle(productLineId)
      await this.updateVehicleAndPriceOfProductLinesWithinProgramProduct(vehicle)
      await this.updateVehicleOfProductLinesWithinProgram(vehicle)

      this.$store.dispatch("offer/setLastSelectedVehicle", {
        offerId: this.offerId,
        vehicle,
      })
    },
    async updateVehicleAndPriceOfProductLinesWithinProgramProduct(
      vehicle,
      productLineId
    ) {
      const programProductProductLinesIds = this.listProductLinesIds
      for (const id of programProductProductLinesIds) {
        if (id !== productLineId && !this.isVehicleSelected(id)) {
          await ProgramProductCustomizationService.updateVehicle(id, {
            ...vehicle,
          })
        }
      }
    },
    async updateVehicleOfProductLinesWithinProgram(vehicle) {
      if (this.isLoggedInSharingMode) {
        await this.$store.dispatch("program/getSharedProgram", this.getSharingToken)
      } else {
        await this.$store.dispatch("program/getProgram", this.$route.params.id)
      }
      const programProductLinesWithoutVehicle =
        this.listProgramProductLinesWithoutVehicle
      const programProductProductLinesIds = this.listProductLinesIds
      for (const productLine of programProductLinesWithoutVehicle) {
        if (!programProductProductLinesIds.includes(productLine.id)) {
          const payload = {
            productLineId: productLine.id,
            data: {
              config: { ...productLine.config, vehicle: vehicle },
            },
          }
          await this.$store.dispatch("productLine/update", payload)
        }
      }
    },
    async initialize() {
      this.isLoading = true
      await ProgramProductCustomizationService.initStores(this.programProductId)
      if (!this.isStoreInit) {
        await this.$store.dispatch("offerContext/initSelectableValues", this.offerId)
      }
      this.productLineIdsToDisplay = this.listProductLinesIds
      await ProgramProductCustomizationService.computeAllPrices()
      await this.$store.dispatch("offer/getCoefficientCriteria", this.offerId)
      this.isLoading = false
    },
  },
  async created() {
    await this.$store.dispatch("offer/setCurrentOfferId", this.offerId)
    if (!this.isOfferExternal(this.offerId)) {
      await this.initialize()
    }
    this.isLoading = false
  },
}
</script>

<style scoped lang="scss">
.offer-guarantees-page {
  background-color: $background-program;

  .offer-page__header {
    margin-bottom: 0.3rem;
  }

  .offer-guarantees {
    padding: 1rem;
    background-color: #fff;
  }

  .offer-page__header {
    margin-bottom: 0.3rem;
  }

  .offer-guarantees-page__header {
    margin-bottom: 1rem;
  }

  .offer-guarantees__title {
    align-self: start;
    justify-self: stretch;
    font-family: "Effra-light", sans-serif;
    border-left-style: solid;
    border-left-color: #c0c8d7;
    padding-left: 0.5rem;
  }

  .product-title-block {
    margin-bottom: 1rem;
  }

  .buttons {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas:
      "empty empty empty empty button-add"
      "empty empty empty empty button-validate";
    align-items: center;
    grid-row-gap: 1rem;

    .add-button {
      grid-area: button-add;
    }

    .validate-button {
      grid-area: button-validate;
    }
  }
}
</style>
