<template>
  <div class="pricing-range" data-cy="pricing-range-page">
    <ModalWarning
      v-if="warningModal.show"
      :title="warningModal.title"
      :reason="listPricingRangeErrors.pricing.errors"
      :advice="warningModal.advice"
      @close="onCloseWarningModal"
    ></ModalWarning>
    <ModalExportPricingRange
      v-if="showExportModal"
      :program-products-id="form.listProgramProductsId"
      :beneficiary-criteria="{}"
      :dynamic-inputs="formVehicles.filters"
      @close="onCloseExportModal"
    ></ModalExportPricingRange>
    <ProgramContentHeader
      class="pricing-range__header"
      :program="getProgram ? getProgram : { offers: [] }"
      :title="getProgram ? getProgram.name : ''"
      :offer-number="offerNumber"
      :desc="$t('offers.description')"
      :other-informations="$t('pricingRange.title')"
      :is-share-button-visible="false"
    ></ProgramContentHeader>
    <div class="col" v-if="isLoading">
      <spinner></spinner>
    </div>
    <div v-else class="pb-1">
      <div class="pricing-range__collapse">
        <button
          type="button"
          class="btn btn-light-gray btn-outline-secondary mb-3 pricing-range__collapse-button"
          @click.prevent="onSubscriptionVarClick"
          data-cy="subscription-variables"
        >
          <i
            class="p-2 icon-chevron-up up7"
            :class="[
              isSubscriptionVarOpen
                ? buttonClasses.isCollapse
                : buttonClasses.isNotCollapse,
            ]"
            aria-hidden="true"
          ></i>
          {{ $t("button.subscriptionVar") }}
        </button>
        <div
          v-if="isSubscriptionVarOpen"
          class="pricing-range__collapse-div"
          data-cy="collapse-variables"
        >
          <VehicleSearch
            v-model="formVehicles"
            :errors="listPricingRangeErrors"
            data-cy="variables-vehicle-filters"
            @update-subscription-var="onDynamicInputsUpdate"
          ></VehicleSearch>
          <ProductLine
            v-for="product in programProducts"
            :key="product.id"
            :product="product"
            @product-line-updated="computePriceByProduct"
          ></ProductLine>
        </div>
      </div>
      <div class="px-4">
        <div class="form-vehicle">
          <button
            type="button"
            class="btn btn-filters p-0 pb-1"
            @click.prevent="onVehicleFiltersVarClick"
            data-cy="pr-filter-vehicle-button"
          >
            {{ $t("button.vehicleFiltersVar") }}
            <i
              class="p-2 icon-chevron-up up7"
              :class="[
                isVehicleFiltersVarOpen
                  ? buttonClasses.isCollapse
                  : buttonClasses.isNotCollapse,
              ]"
              aria-hidden="true"
            ></i>
          </button>
          <FormVehicleFilters
            v-if="isVehicleFiltersVarOpen"
            v-model="form"
            :errors="listPricingRangeErrors"
            data-cy="vehicle-filters"
            @filter-input="onFilterInput"
            @reset-filters="onResetFiltersButtonClick"
          ></FormVehicleFilters>
        </div>

        <div class="mt-5 pricing-range__table">
          <div class="mt-5 mb-3 text-center" v-if="!hasResults">
            <p class="font-size-20" data-cy="pricing-range-no-results-message-part1">
              {{ $t("pricingRange.no_result.part1") }}
            </p>
            <p
              class="font-size-14 mb-5"
              data-cy="pricing-range-no-results-message-part2"
            >
              {{ $t("pricingRange.no_result.part2") }}
            </p>
            <button
              @click="onNextButtonClick"
              class="btn btn-primary btn-block ml-auto mt-5"
              data-cy="pr-next-button"
            >
              {{ $t("button.next") }}
            </button>
          </div>
          <div v-else>
            <spinner v-if="isVehicleListLoading" class="mt-5"></spinner>
            <div v-else>
              <PricingRangeTable
                :list-vehicles="listPricingRangeVehicles"
                :products="programProducts"
                :is-program-product-pricing-range="isProgramProductPricingRange"
                :is-payment-frequency-monthly="isPaymentFrequencyMonthly(offerId)"
              ></PricingRangeTable>
              <p
                v-if="isPaymentFrequencyMonthly(offerId)"
                class="pricing-range__table-mention text-sm-right"
                data-cy="monthly-payment-mention"
              >
                {{ $t("pricingRange.monthly_prices") }}
              </p>
              <div class="pricing-range__bottom">
                <div class="pricing-range__pagination" data-cy="current-page">
                  <h6 class="mr-2">{{ $t("pricingRange.current_page_label") }}</h6>
                  <span
                    v-for="pageIndex in pageIndexes"
                    :key="`${pageIndex}-page-index`"
                    class="ml-2 mouse-pointer"
                    :class="{ active: getCurrentPage === pageIndex }"
                    @click="onPageIndexClick(pageIndex)"
                  >
                    {{ pageIndex }}
                  </span>
                </div>
              </div>
              <div class="pricing-range__bottom-buttons">
                <div class="pricing-range__buttons">
                  <button
                    type="button"
                    class="btn btn-light-gray btn-outline-secondary btn-block"
                    data-cy="button-export"
                    @click.prevent="onButtonExportClick"
                  >
                    <i class="p-2 icon-download2 download" aria-hidden="true"></i>
                    {{ $t("button.exportPricingRangeSample") }}
                  </button>
                  <button
                    v-if="hasBackButton"
                    @click="onBackToProgramClick"
                    class="btn btn-primary btn-block"
                    data-cy="return-to-program-button"
                  >
                    {{ $t("button.returnToProgram") }}
                  </button>
                  <button
                    v-else
                    @click="onValidateButtonClick"
                    class="btn btn-primary btn-block ml-auto"
                    data-cy="pr-validate-button"
                  >
                    {{ $t("button.validate") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import ModalExportPricingRange from "@/components/pricing/modal/ModalExportPricingRange"
import ProductLine from "@/views/pricing/ProductLine"
import PricingRangeTable from "@/views/pricing/PricingRangeTable"
import ModalWarning from "@/components/modal/ModalWarning"
import DateService from "@/services/technical/DateService"
import ProgramContentHeader from "@/components/presales/components/ProgramContentHeader"
import VehicleSearch from "@/views/pricing/VehicleSearch"
import ArrayService from "@/services/technical/ArrayService"
import ProgramService from "@/services/business/ProgramService"

export default {
  name: "PricingRange",
  components: {
    ProgramContentHeader,
    ModalWarning,
    PricingRangeTable,
    ModalExportPricingRange,
    ProductLine,
    VehicleSearch,
  },
  props: {
    id: { type: [Number, String], required: true }, //==> id du programme
    offerId: { type: [String, Number], required: true },
    sharedToken: { type: [Number, String] },
    productLineId: { type: [Number, String] },
  },
  data() {
    return {
      form: {
        listProgramProductsId: null,
        filters: {
          make: { inputType: "select", outputType: Array, value: [], choices: {} },
          model: { inputType: "select", outputType: Array, value: [], choices: {} },
          trim_level: {
            inputType: "select",
            outputType: Array,
            value: [],
            choices: {},
          },
          year: { inputType: "search", outputType: Number, value: "" },
        },
      },
      engine_code: null,
      listVehiclesByProduct: [],
      isLoading: true,
      isVehicleListLoading: false,
      productLinesEligibility: {},
      showExportModal: false,
      isSubscriptionVarOpen: false,
      isVehicleFiltersVarOpen: false,
      buttonClasses: {
        isCollapse: "icon-chevron-up up7",
        isNotCollapse: "icon-chevron-down down7",
      },
      warningModal: {
        show: false,
        title: this.$t("pricingRange.failed_modal.title"),
        advice: this.$t("pricingRange.failed_modal.advice"),
      },
      program: null,
      formVehicles: {
        filters: {
          km: {
            inputType: "search",
            outputType: Number,
            value: 1,
            placeholder: "",
          },
          first_release_date: {
            inputType: "search",
            outputType: String,
            value: DateService.format(new Date(), "YYYY-MM-DD"),
          },
        },
      },
      programProductId: null,
    }
  },
  computed: {
    ...mapGetters("productLine", [
      "listPricingRange",
      "listPricingRangeVehicles",
      "listPricingRangeCount",
      "getConfig",
      "listProductLines",
      "hasProductLine",
      "listPricingRangeErrors",
      "listPricingRange",
      "hasPricingRangeErrors",
      "getCurrentPage",
    ]),
    ...mapGetters("repository", ["listMakes", "listModels", "listTrimLevels"]),
    ...mapGetters("programProduct", ["getProgramProductById", "isVn"]),
    ...mapGetters("program", [
      "getProgramByOfferId",
      "getProgram",
      "getCurrentProgramId",
      "getCurrentProgramEngineTagLabel",
      "getCurrentProgramVehicleTagLabel",
    ]),
    ...mapGetters("offer", [
      "getOffer",
      "listEligibilityCriteriaValues",
      "listProgramProducts",
      "isLastProgramProduct",
      "getNextProgramProductId",
      "isPaymentFrequencyMonthly",
      "getOfferNumberInProgram",
      "isOfferExternal",
    ]),
    ...mapGetters("auth", ["getRouteName", "hasSharingToken"]),
    pageIndexes() {
      // Only 3 pages max are displayed (i.e. indexes 0, 1 and 2)
      const lastPageIndex =
        this.listPricingRangeCount > 30 ? 3 : Math.ceil(this.listPricingRangeCount / 10)
      return Array.from({ length: lastPageIndex }, (_, i) => i + 1)
    },
    offerNumber() {
      return this.getOfferNumberInProgram(parseInt(this.offerId))
    },
    offerPricingTitle() {
      return `${this.$t("offers.pricing_summary.title")}${this.offerNumber}`
    },
    pricingRangeErrorsIncludeFormVehiclesFilters() {
      const formVehiclesFilters = Object.keys(this.formVehicles.filters)
      return formVehiclesFilters.some((filter) =>
        Object.keys(this.listPricingRangeErrors.pricing.errors).includes(filter)
      )
    },
    isProgramProductPricingRange() {
      return (
        this.$route.name === "program_product_pricing_range" ||
        this.$route.name === "shared_program_product_pricing_range"
      )
    },
    hasBackButton() {
      return this.hasSharingToken || this.isProgramProductPricingRange
    },
    offerMaxKm() {
      return this.listEligibilityCriteriaValues(this.offerId).max_km
    },
    kmInputDefaultValue() {
      if (this.getCurrentProgramVehicleTagLabel === "VNPJ") {
        return 0
      }
      // Use half of the "max_km" eligibility criteria of the offer, if filled
      return this.offerMaxKm !== null ? parseInt(this.offerMaxKm / 2) : 60000
    },
    kmInputPlaceholder() {
      // Use the "max_km" eligibility criteria of the offer, if filled
      return this.offerMaxKm !== null ? `< ${this.offerMaxKm} km` : ""
    },
    programProducts() {
      if (this.isProgramProductPricingRange) {
        // Display pricing range for current program product only !
        return [this.getProgramProductById(this.programProductId)]
      }
      return this.listProgramProducts(this.offerId)
    },
    hasResults() {
      return this.listPricingRangeVehicles && this.listPricingRangeVehicles.length > 0
    },
    isVnpj() {
      return this.getCurrentProgramVehicleTagLabel === "VNPJ" ? true : null
    },
  },
  methods: {
    async onBackToProgramClick() {
      if (this.sharedToken && this.isProgramProductPricingRange) {
        await this.$router.push({
          name: "shared_programProduct",
          params: { sharedToken: this.sharedToken },
        })
      } else if (this.sharedToken) {
        await this.$router.push({
          name: "shared_program",
          params: { sharedToken: this.sharedToken },
        })
      } else if (this.isProgramProductPricingRange) {
        await this.$router.push({
          name: "programProduct",
          params: { id: this.getCurrentProgramId },
        })
      } else {
        await this.$router.push({
          name: "offerPricingRange",
          params: { id: this.getCurrentProgramId },
        })
      }
    },
    onButtonExportClick() {
      this.showExportModal = true
    },
    onCloseExportModal() {
      this.showExportModal = false
    },
    async onPageIndexClick(index) {
      this.isVehicleListLoading = true
      const payload = {
        page: index,
        program_products_pk: this.form.listProgramProductsId,
      }
      await this.$store.dispatch("productLine/listVehicles", payload)
      this.listVehiclesByProduct.vehicles = this.listPricingRangeVehicles
      this.isVehicleListLoading = false
    },
    onSubscriptionVarClick() {
      this.isSubscriptionVarOpen = !this.isSubscriptionVarOpen
    },
    onVehicleFiltersVarClick() {
      this.isVehicleFiltersVarOpen = !this.isVehicleFiltersVarOpen
    },
    async computePriceByProduct() {
      this.isVehicleListLoading = true
      this.listVehiclesByProduct = []
      await this.$store.dispatch("productLine/listVehicles", {
        program_products_pk: this.form.listProgramProductsId,
        label: this.getCurrentProgramVehicleTagLabel,
        first_release_date: this.formVehicles.filters.first_release_date.value,
        km: this.formVehicles.filters.km.value,
      })
      const vehicles = this.listPricingRange

      if (!this.hasPricingRangeErrors) {
        this.listVehiclesByProduct = vehicles
      } else if (!this.pricingRangeErrorsIncludeFormVehiclesFilters) {
        // (errors on formVehicles filters are directly managed by the FormRowInput in VehicleSearch component)
        this.warningModal.show = true
      }
      this.isVehicleListLoading = false
    },
    async onFilterInput(filter) {
      this.isVehicleListLoading = true
      if (filter === "make") {
        const makes = this.form.filters.make.value
        if (makes.length > 0) {
          await this.$store.dispatch("repository/selectMake")
          await this.$store.dispatch("repository/retrieveModels", makes)
          this.form.filters.model.choices = this.listModels
        } else {
          await this.resetAllFiltersExceptModelYear()
        }
      } else if (filter === "model") {
        const models = this.form.filters.model.value
        if (models.length > 0) {
          await this.$store.dispatch("repository/selectModel", models)
          let versionResult = await this.$store.dispatch(
            "repository/retrieveTrimLevels",
            models
          )
          this.form.filters.trim_level.choices = this.listTrimLevels
        } else {
          this.resetTrimLevels()
        }
      }
      await this.$store.dispatch("productLine/resetPricingRangeFilters")
      this.setPricingRangeFilters()
      await this.$store.dispatch(
        "productLine/setPricingRangeFilters",
        this._getNotEmptyFilter()
      )
      await this.$store.dispatch("productLine/listVehicles", {
        program_products_pk: this.form.listProgramProductsId,
      })
      this.listVehiclesByProduct = this.listPricingRange
      this.isVehicleListLoading = false
    },
    _getNotEmptyFilter() {
      return Object.entries(this.form.filters).reduce((acc, [filter, { value }]) => {
        if (value.length > 0) {
          if (filter === "year") {
            acc[filter] = value.split()
          } else {
            acc[filter] = value
          }
        }
        return acc
      }, {})
    },
    async resetFilters(mustModelYearBeReset = true) {
      await this.$store.dispatch("repository/selectMake")
      await this.$store.dispatch("productLine/resetPricingRangeFilters")
      this.setPricingRangeFilters()
      this.form.filters.make.value = []
      this.form.filters.model.value = []
      this.form.filters.trim_level.value = []
      this.form.filters.make.choices = this.listMakes
      this.form.filters.model.choices = this.listModels
      this.form.filters.trim_level.choices = {}
      if (mustModelYearBeReset) {
        this.form.filters.year.value = ""
      }
    },
    resetTrimLevels() {
      this.form.filters.trim_level.value = []
      this.form.filters.trim_level.choices = {}
    },
    async onResetFiltersButtonClick() {
      await this.resetFilters()
      await this.resetStoreData()
      await this.computePriceByProduct()
    },
    async resetStoreData() {
      this.isVehicleListLoading = true
      await this.$store.dispatch("productLine/resetPricingRangeErrors")
      this.setPricingRangeFilters()
      await this.$store.dispatch("productLine/listVehicles", {
        program_products_pk: this.form.listProgramProductsId,
      })
      this.isVehicleListLoading = false
    },
    async resetAllFiltersExceptModelYear() {
      await this.resetFilters(false)
    },
    onCloseWarningModal() {
      this.warningModal.show = false
    },
    async onDynamicInputsUpdate(dynamicInputs) {
      this.formVehicles.filters.first_release_date.value =
        dynamicInputs.first_release_date
      this.formVehicles.filters.km.value = dynamicInputs.km
      this.setPricingRangeFilters()
      await this.computePriceByProduct()
    },
    hasAnotherOfferToConfigure() {
      const numberOfOffers = this.program.offers.length
      return (
        numberOfOffers > 1 &&
        this.program.offers[numberOfOffers - 1].id !== parseInt(this.offerId)
      )
    },
    async onValidateButtonClick() {
      await this.validateOfferAndGoToNextStep()
    },
    async onNextButtonClick() {
      if (this.isProgramProductPricingRange) {
        await this.validateProgramProductAndGoToNextStep()
      } else {
        await this.validateOfferAndGoToNextStep()
      }
    },
    async validateProgramProductAndGoToNextStep() {
      await this.$store.dispatch("programProduct/activate", this.programProductId)
      // refresh offer
      await this.$store.dispatch("offer/getOffer", this.offerId)

      if (!this.isLastProgramProduct(this.offerId, this.programProductId)) {
        await this.$router.push({
          name: this.getRouteName("programProduct"),
          params: {
            programProductId: this.getNextProgramProductId(
              this.offerId,
              this.programProductId
            ),
          },
        })
      } else {
        await this.$router.push({
          name: this.getRouteName("offerPricingRange"),
          // in sharing space, we need to pass the program id as it's not in the URL
          params: this.getSharingToken
            ? { id: this.getProgramByOfferId(this.offerId).id }
            : {},
        })
      }
    },
    async validateOfferAndGoToNextStep() {
      await this.$store.dispatch("offer/activate", this.offerId)
      this.program = this.getProgramByOfferId(parseInt(this.offerId))

      if (this.hasAnotherOfferToConfigure()) {
        const nextOfferId = parseInt(this.offerId) + 1
        if (this.isOfferExternal(nextOfferId)) {
          await this.$store.dispatch("offer/setCurrentOfferId", nextOfferId)
          await this.$router.push({
            name: this.getRouteName("programProduct"),
            params: {
              offerId: nextOfferId,
              programProductId: this.getOffer(nextOfferId).program_products[0].id,
            },
          })
        } else {
          await this.$router.push({
            name: this.getRouteName("programOffer"),
            params: { offerId: nextOfferId },
          })
        }
      } else {
        await this.$router.push({
          name: this.getRouteName("programHome"),
          params: { id: this.id },
        })
      }
    },
    getSmallestProductLineId(productLines) {
      return ArrayService.sortIntegers(
        productLines.map((productLine) => productLine.id)
      )[0]
    },
    setPricingRangeFilters() {
      const formattedDate = DateService.format(
        this.formVehicles.filters.first_release_date.value,
        "YYYY-MM-DD"
      )
      const engine_type =
        this.engine_code !== null
          ? this.engine_code
          : ProgramService.listEngineCodesFromEngineTag(
              this.getProgram.engine_tag.label
            )
      const payload = {
        km: this.formVehicles.filters.km.value,
        first_release_date: formattedDate,
        engine_type: engine_type,
        is_vn: this.isVnpj,
        ...(this.form.filters.make.value.length > 0 && {
          make: this.form.filters.make.value,
        }),
        ...(this.form.filters.model.value.length > 0 && {
          model: this.form.filters.model.value,
        }),
      }
      this.$store.dispatch("productLine/setPricingRangeFilters", payload)
    },
    async retrieveOfferEligibilityCriteria() {
      const eligibilityCriteria = this.listEligibilityCriteriaValues(this.offerId)
      if (eligibilityCriteria.make.length > 0) {
        const eligibilityMakes = eligibilityCriteria.make.map((make) =>
          make.id.toString()
        )
        this.form.filters.make.value = eligibilityMakes
        await this.$store.dispatch("repository/setMakes", eligibilityCriteria.make)
        this.form.filters.make.choices = this.listMakes
        await this.$store.dispatch("repository/retrieveModels", eligibilityMakes)
        this.form.filters.model.choices = this.listModels
      } else {
        await this.$store.dispatch("repository/setMakes")
        this.form.filters.make.choices = this.listMakes
      }
      if (eligibilityCriteria.model.length > 0) {
        const eligibilityModels = eligibilityCriteria.model.map((model) =>
          model.id.toString()
        )
        this.form.filters.model.value = eligibilityModels
        await this.$store.dispatch("repository/setModels", eligibilityCriteria.model)
        this.form.filters.model.choices = this.listModels
      }
      if (eligibilityCriteria.engine_code.length > 0) {
        this.engine_code = eligibilityCriteria.engine_code
      }
    },
  },
  async created() {
    this.programProductId = this.$route.params.programProductId
    this.form.listProgramProductsId = this.programProducts.map((product) => product.id)
    if (!this.isVn(this.programProducts[0].id)) {
      this.form.filters = Object.assign(
        {
          year: {
            inputType: "search",
            outputType: Number,
            value: null,
          },
        },
        this.form.filters
      )
    }
    await this.resetFilters()
    await this.retrieveOfferEligibilityCriteria()
    this.formVehicles.filters.km.placeholder = this.kmInputPlaceholder
    this.formVehicles.filters.km.value = this.kmInputDefaultValue
    this.setPricingRangeFilters()
    await this.computePriceByProduct()
    this.isLoading = false
  },
}
</script>

<style lang="scss" scoped>
.product-line-table {
  display: flex;
}

.pricing-range {
  background-color: $background-program;

  .pricing-range__header {
    margin-bottom: 0.3rem;
  }

  .pricing-range__table-mention {
    color: $bleucaarea;
    font-size: 13px;
  }

  .pricing-range__collapse {
    padding: 2rem 1.5rem;
    background-color: #fff;
    display: grid;
    grid-template-columns: 2fr 1fr;

    .pricing-range__collapse-button {
      width: 100%;
      grid-column: 3 / span 1;
    }

    .pricing-range__collapse-div {
      grid-column: 1 / span 3;
    }
  }

  .pricing-range__bottom {
    grid-column: 1 / span 2;
    grid-row: 2 / span 2;
    padding-bottom: 1rem;
  }

  &__pagination {
    display: flex;
  }
}

.pricing-range__bottom-buttons {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-areas: "empty buttons";
  justify-content: end;
  margin-bottom: 1rem;
}

.pricing-range__buttons {
  grid-area: buttons;
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
  background-color: #fff;
}

.download {
  font-size: 16px;
  font-weight: bold;
}

.btn-filters {
  min-width: auto;
}

div.form-vehicle {
  .form-vehicle-container {
    background-color: $background-program;
    display: grid;
    grid-template-columns: 1fr;
  }
}

.pricing-range__pagination {
  span {
    color: $bleuc100;
    &:hover,
    &.active {
      color: $bleucaarea;
    }
  }
}
</style>
