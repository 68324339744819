// STATES (snake_case)
import Vue from "vue"
import HttpService, { HttpError } from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"
import BeneficiaryCriteriaService from "../services/business/BeneficiaryCriteriaService"
import i18n from "@/i18n"

const external_product = [""]

const state = {
  product_lines: null,
  prices: null,
  coefficient_criteria: null,
  update_errors: {},
  vehicle_choice_error: null,
  pricing_errors: null,
  eligibility_criteria_dependency: {
    max_age: ["max_age_unity"],
  },
  change_messages: {},
  list_pricing_range: null,
  pricing_range_filters: {},
  pricing_range_errors: {},
  pricing_range_product_line_id: null,
  current_page: 1,
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_PRODUCT_LINES(state, productLines) {
    state.product_lines = productLines.reduce((acc, pl) => {
      acc[pl.id] = pl
      return acc
    }, {})
  },
  SET_PRODUCT_LINE(state, productLine) {
    if (state.product_lines === null) state.product_lines = {}
    Vue.set(state.product_lines, productLine.id, productLine)
  },
  DEL_PRODUCT_LINE(state, productLineId) {
    Vue.delete(state.product_lines, productLineId)
  },
  SET_COEFFICIENT_CRITERIA(state, payload) {
    if (state.coefficient_criteria === null) state.coefficient_criteria = {}
    Vue.set(state.coefficient_criteria, payload.id, payload.coefficientCriteria)
  },
  RESET_PRODUCT_LINE_UPDATE_ERRORS(state, remove_id) {
    state.update_errors = Object.keys(state.update_errors).reduce((acc, id) => {
      if (parseInt(id) !== parseInt(remove_id)) {
        acc[id] = state.update_errors[id]
      }
      return acc
    }, {})
  },
  SET_PRODUCT_LINE_UPDATE_ERRORS(state, payload) {
    Vue.set(state.update_errors, payload.id, payload.errors)
  },
  SET_PRODUCT_LINE_VEHICLE_CHOICE_ERROR(state, error) {
    state.vehicle_choice_error = error
  },
  SET_PRODUCT_LINE_CHANGE_MESSAGES(state, payload) {
    Vue.set(state.change_messages, payload.id, payload.change_messages)
  },
  RESET_PRODUCT_LINE_CHANGE_MESSAGES(state, productLineId) {
    Vue.set(state.change_messages, productLineId, {})
  },
  SET_PRICING_RANGE(state, listPricingRange) {
    state.list_pricing_range = listPricingRange
  },
  SET_PRICING_RANGE_FILTER(state, filter) {
    Vue.set(state.pricing_range_filters, filter.name, filter.value)
  },
  RESET_PRICING_RANGE_FILTER(state) {
    state.pricing_range_filters = {}
  },
  SET_PRICING_RANGE_FILTERS_ERRORS(state, errors) {
    state.pricing_range_errors = { filters: errors }
  },
  SET_PRICING_RANGE_EXPORT_ERRORS(state, errors) {
    state.pricing_range_errors = { export: errors }
  },
  RESET_PRICING_RANGE_ERRORS(state) {
    state.pricing_range_errors = {}
  },
  SET_PRODUCT_LINE_PRICE(state, payload) {
    if (state.prices === null) state.prices = {}
    Vue.set(state.prices, payload.productLineId, {
      price: payload.price,
      currency_code: payload.currency_code,
    })
  },
  RESET_PRODUCT_LINE_PRICE(state, productLineId) {
    if (state.prices !== null && state.prices.hasOwnProperty(productLineId)) {
      Vue.set(state.prices, productLineId, null)
    }
  },
  RESET_PRODUCT_LINE_PRICES(state) {
    state.prices = null
  },
  SET_PRICING_ERROR(state, payload) {
    if (state.pricing_errors === null) state.pricing_errors = {}
    Vue.set(state.pricing_errors, payload.productLineId, payload.error)
  },
  RESET_PRICING_ERROR(state, productLineId) {
    if (
      state.pricing_errors !== null &&
      state.pricing_errors.hasOwnProperty(productLineId)
    ) {
      Vue.set(state.pricing_errors, productLineId, null)
    }
  },
  RESET_VEHICLE_CHOICE_ERROR(state) {
    state.vehicle_choice_error = null
  },
  SET_CURRENT_PAGE(state, pageNumer) {
    state.current_page = pageNumer
  },
}

// ACTIONS (camelCase)
const actions = {
  async getCoefficientCriteria({ commit }, productLineId) {
    try {
      const coefficientCriteria = await HttpService.get(
        UrlService.render("productLineCoefficientCriteria", { id: productLineId })
      )
      commit("SET_COEFFICIENT_CRITERIA", {
        id: productLineId,
        coefficientCriteria: coefficientCriteria,
      })
    } catch (e) {
      console.error(e)
      throw e
    }
  },

  async getProductLine({ commit }, productLineId) {
    try {
      const productLine = await HttpService.get(
        UrlService.render("productLineById", { id: productLineId })
      )
      commit("SET_PRODUCT_LINE", productLine)
    } catch (e) {
      console.error("getProductLine failed: ", e)
      throw e
    }
  },

  async update({ commit, dispatch, getters }, payload) {
    try {
      commit("RESET_PRODUCT_LINE_UPDATE_ERRORS", payload.productLineId)
      let updatedProductLine = await HttpService.put(
        UrlService.render("productLineById", { id: payload.productLineId }),
        payload.data
      )
      commit("SET_PRODUCT_LINE", updatedProductLine)
      commit("SET_PRODUCT_LINE_CHANGE_MESSAGES", {
        id: payload.productLineId,
        change_messages: updatedProductLine.config.change_messages,
      })

      if (
        BeneficiaryCriteriaService.hasCoverageCriteria(
          getters.getBeneficiaryCriteriaValues(updatedProductLine.id)
        )
      ) {
        const updateCoverageOptionsPayload = {
          productLineId: payload.productLineId,
          config: updatedProductLine.config,
          values: getters.getBeneficiaryCriteriaValues(updatedProductLine.id),
        }
        dispatch("gui/updateCoverageOptions", updateCoverageOptionsPayload, {
          root: true,
        })
      }
    } catch (e) {
      if (e instanceof HttpError) {
        if (e.status === 422 || e.status === 400) {
          if (e.data.config.vehicle) {
            commit(
              "SET_PRODUCT_LINE_VEHICLE_CHOICE_ERROR",
              i18n.t("productLines.vehicle_non_eligible")
            )
          } else {
            commit("SET_PRODUCT_LINE_UPDATE_ERRORS", {
              id: payload.productLineId,
              errors: e.data,
            })
          }

          return
        }
      }
      console.error("failed: ", e)
      throw e
    }
  },

  async removeProductLine({ commit }, productLineId) {
    try {
      await HttpService.delete(
        UrlService.render("productLineById", { id: productLineId })
      )
      commit("DEL_PRODUCT_LINE", productLineId)
    } catch (e) {
      console.error("removeProductLine() failed : ", e)
      throw e
    }
  },

  setProductLines({ commit, dispatch, getters }, productLines) {
    commit("SET_PRODUCT_LINES", productLines)
    productLines.forEach((productLine) => {
      if (productLine.config !== null) {
        if (
          BeneficiaryCriteriaService.hasCoverageCriteria(
            getters.getBeneficiaryCriteriaValues(productLine.id)
          )
        ) {
          const updateCoverageOptionsPayload = {
            productLineId: productLine.id,
            config: productLine.config,
            values: getters.getBeneficiaryCriteriaValues(productLine.id),
          }
          dispatch("gui/updateCoverageOptions", updateCoverageOptionsPayload, {
            root: true,
          })
        }
      }
    })
  },

  resetPricingRangeErrors({ commit }) {
    commit("RESET_PRICING_RANGE_ERRORS")
  },

  async resetPricingRangeFilters({ commit }) {
    commit("RESET_PRICING_RANGE_FILTER")
  },
  async listVehicles({ state, commit, dispatch }, payload = {}) {
    dispatch("resetPricingRangeErrors")
    payload = { ...state.pricing_range_filters, ...payload }
    try {
      const isNotPageOne = payload.page && payload.page !== 1
      let listPricingRange = {}
      if (isNotPageOne) {
        const page = payload.page
        delete payload.page
        listPricingRange = await HttpService.post(
          process.env.VUE_APP_ENDPOINT + "product-lines/pricing-range/?page=" + page,
          payload
        )
        commit("SET_CURRENT_PAGE", page)
      } else {
        listPricingRange = await HttpService.post(
          UrlService.render("productLinePricingRange"),
          payload
        )
        commit("SET_CURRENT_PAGE", 1)
      }
      commit("SET_PRICING_RANGE", listPricingRange)
    } catch (e) {
      if (e && e.status && e.status === 422) {
        commit("SET_PRICING_RANGE_FILTERS_ERRORS", e.data)
      }
      console.error("failed: ", e)
    }
  },
  setPricingRangeFilters({ commit }, payload) {
    for (let filter in payload) {
      commit("SET_PRICING_RANGE_FILTER", {
        name: filter,
        value: payload[filter],
      })
    }
  },
  async exportPricingRange({ state, commit }, payload) {
    payload = { ...state.pricing_range_filters, ...payload }
    try {
      return await HttpService.post(
        UrlService.render("productLinePricingRange"),
        payload,
        {
          headers: {
            Accept: "application/json, application/pdf",
          },
          responseType: "arraybuffer",
        }
      )
    } catch (e) {
      if (e && e.status && e.status === 422) {
        // Need to convert e.data as it is an ArrayBuffer
        commit("SET_PRICING_RANGE_EXPORT_ERRORS", JSON.parse(Buffer.from(e.data)))
      }
      console.error("failed: ", e)
      throw e
    }
  },

  async getProductLinesPrices({ commit }, payload) {
    try {
      const prices = await HttpService.post(UrlService.render("productLinePrices"), {
        product_line_bundles: payload,
      })
      prices.forEach((price) => {
        commit("SET_PRODUCT_LINE_PRICE", {
          productLineId: price.product_line_id,
          price: price.price,
          currency_code: price.currency_code,
        })
      })
    } catch (e) {
      console.error("getProductLinesPrices failed: ", e)
      payload.forEach((productLine) => {
        commit("SET_PRODUCT_LINE_PRICE", {
          productLineId: productLine.product_line_id,
          price: 0,
          currency_code: "EUR",
        })
      })
      throw e
    }
  },
  resetProductLinesPrices({ commit }) {
    commit("RESET_PRODUCT_LINE_PRICES")
  },
  resetUpdateErrors({ commit }, productLineId) {
    commit("RESET_PRODUCT_LINE_UPDATE_ERRORS", productLineId)
  },
  resetPricingError({ commit }, productLineId) {
    commit("RESET_PRICING_ERROR", productLineId)
  },
  resetVehicleChoiceError({ commit }) {
    commit("RESET_VEHICLE_CHOICE_ERROR")
  },
}

// GETTERS (camelCase)
const getters = {
  hasProductLine: (state) => (id) =>
    state.product_lines !== null && id in state.product_lines,
  listProductLines: (state) => Object.values(state.product_lines),
  getConfig: (state) => (id) =>
    JSON.parse(JSON.stringify(state.product_lines[id].config)),
  getUserSelectableValues: (state) => (id) =>
    JSON.parse(JSON.stringify(state.product_lines[id].config.user_selectable_values)),
  hasUpdateErrors: (state) => (productLineId) =>
    state.update_errors.hasOwnProperty(productLineId) &&
    state.update_errors[productLineId] !== null,
  hasVehicleChoiceError: (state) => state.vehicle_choice_error !== null,
  getVehicleChoiceErrorDetail: (state) => state.vehicle_choice_error,
  getUpdateErrors: (state, getters) => (id) => {
    return getters.hasUpdateErrors(id) ? state.update_errors[id] : {}
  },
  getProductLineUwr: (state) => (id) =>
    state.product_lines[id].config.commercial.values.uwr_target,
  /**
   * Payment Frequency
   */
  getPaymentFrequencyValue: (state) => (productLineId) =>
    state.product_lines[productLineId]
      ? state.product_lines[productLineId].config["payment_frequency"]["value"]
      : null,
  getPaymentFrequencyOptionsTranslated: (state, getters) => (productLineId) => {
    if (!state.product_lines.hasOwnProperty(productLineId)) {
      return {}
    }
    const availableOptions = getters.getConfig(productLineId).payment_frequency.options
    const translations = getters.getConfig(productLineId).i18n.payment_frequency
    return Object.entries(availableOptions).reduce(
      (translatedOptions, [value, translationKey]) => {
        translatedOptions[value] = translations[translationKey]
        return translatedOptions
      },
      {}
    )
  },
  isMonthlyPayment: (state, getters) => (id) => {
    return getters.getPaymentFrequencyValue(id) === "monthly"
  },
  /**
   * Coefficient Criteria
   */
  listCoefCritCustom: (state) => (id) =>
    state.product_lines[id].config["coefficient"]["custom"],
  listCoefCritProgramByGroup: (state) => (id, group) => {
    return state.product_lines[id].config["coefficient"]["program"][group]
  },
  getCoefCritOptions: (state) => (productLineId, criterion) => {
    if (state.coefficient_criteria !== null) {
      return state.coefficient_criteria[productLineId]
        ? state.coefficient_criteria[productLineId][criterion]
        : {}
    }
    return {}
  },
  getCoefCritValue: (state) => (id, criterion) =>
    state.product_lines[id]
      ? state.product_lines[id].config["coefficient"]["values"][criterion]
      : [],
  getCoefCritDefaultValue: (state) => (id, criterion) =>
    state.product_lines[id].config["coefficient"]["default_values"][criterion],
  getCoefCritValueLabel: (state, getters) => (id, criterion) =>
    getters.getCoefCritOptions(id, criterion)[getters.getCoefCritValue(id, criterion)],
  getCoefficientCriteria: (state) => (id) =>
    state.coefficient_criteria && state.coefficient_criteria.hasOwnProperty(id)
      ? state.coefficient_criteria[id]
      : null,
  getBeneficiaryCriteriaDefaultValues: (state, getters) => (id) => {
    return state.product_lines[id].config.coefficient.beneficiary.reduce(
      (defaultValues, crit) => {
        defaultValues[crit] = getters.getCoefCritDefaultValue(id, crit)
        return defaultValues
      },
      {}
    )
  },
  getBeneficiaryCriteriaValues: (state, getters) => (id) => {
    return state.product_lines[id].config.coefficient.beneficiary.reduce(
      (values, crit) => {
        values[crit] = getters.getCoefCritValue(id, crit)
        return values
      },
      {}
    )
  },
  /**
   * Eligibility Criteria
   */
  listEligibilityCriteria: (state) => (id) =>
    Object.keys(state.product_lines[id].config["eligibility"]["default_values"]),
  getEligibilityCriteriaDefaultValues: (state) => (productLineId) =>
    JSON.parse(
      JSON.stringify(
        state.product_lines[productLineId].config["eligibility"]["default_values"]
      )
    ),
  listMandatoryEligibilityCriteria: (state) => (productLineId) =>
    JSON.parse(
      JSON.stringify(
        state.product_lines[productLineId].config["eligibility"]["mandatory"]
      )
    ),
  /**
   * Pricing range
   */
  listPricingRange: (state) => state.list_pricing_range,
  listPricingRangeVehicles: (state) => state.list_pricing_range.results,
  listPricingRangeCount: (state) => state.list_pricing_range.count,
  listPricingRangeErrors: (state) => state.pricing_range_errors,
  hasPricingRangeErrors: (state) =>
    state.pricing_range_errors.hasOwnProperty("pricing"),
  hasPricingRangeExportErrors: (state) =>
    state.pricing_range_errors.hasOwnProperty("export"),
  listPricingRangeExportErrors: (state, getters) =>
    getters.hasPricingRangeExportErrors ? state.pricing_range_errors.export : {},
  /**
   * Price
   */
  getProductLinePrice: (state) => (productLineId) => {
    return state.prices &&
      productLineId in state.prices &&
      state.prices[productLineId] !== null
      ? state.prices[productLineId].price
      : null
  },
  getProductLineCurrencyCode: (state) => (productLineId) => {
    return state.prices && productLineId in state.prices
      ? state.prices[productLineId].currency_code
      : null
  },
  getTotalProductLinesPrice: (state) => {
    if (state.prices) {
      let total = 0
      for (let productLineId in state.prices) {
        total += state.prices[productLineId].price
      }
      return total
    }
    return null
  },
  hasPricingError: (state) => (productLineId) =>
    state.pricing_errors !== null &&
    state.pricing_errors.hasOwnProperty(productLineId) &&
    state.pricing_errors[productLineId] !== null,
  getPricingError: (state, getters) => (productLineId) =>
    getters.hasPricingError(productLineId) ? state.pricing_errors[productLineId] : null,
  getCurrentPage: (state) => state.current_page,
  isProductLineExternal: (state) => (productLineId) =>
    state.product_lines[productLineId].is_external,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
